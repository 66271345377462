import { JSX }	from "preact";

import { Button }		from "@v3/geko/Button";

import { permissions }	from "@geotoura/shared/authorization";

import { useMessages }	from "@geotoura/floater/useMessages";
import * as actions		from "@geotoura/floater/actions";

export type HeadlineProps	= Readonly<{
	text1:	string,
	text2?:	string,
}>;

export const Headline = ({ text1, text2 }:HeadlineProps):JSX.Element => {
	const msg = useMessages();

	return (
		<div class="floater-headline">
			<h2 class="hl hl-3">
				<span class="hl-text1">{text1}</span>
				{ text2 !== undefined && text2 !== "" &&
					<>
						<span> </span>
						<span class="hl-text2">{text2}</span>
					</>
				}
			</h2>
			<Button
				label={msg.util.close}
				icon="fa-close"
				layout="always-icon"
				style="hoverinvert"
				permission={permissions.useWebsite}
				action={() => actions.close()}
			/>
		</div>
	);
};
