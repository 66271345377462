import { JSX }	from "preact";

import { Button }		from "@v3/geko/Button";
import { ButtonBar }	from "@v3/geko/ButtonBar";
import { ExternalLink }	from "@v3/geko/ExternalLink";

import { permissions }	from "@geotoura/shared/authorization";

import { useMessages }	from "@geotoura/floater/useMessages";
import * as actions		from "@geotoura/floater/actions";
import { Headline }		from "@geotoura/floater/Headline";

export type ErrorProps = Readonly<unknown>;

export const Error = ():JSX.Element => {
	const msg = useMessages();

	return (
		<div class="floater-error">
			<Headline text1={msg.util.error.title}/>
			<p>
				{msg.util.error.text1}
				{" "}
				<ExternalLink
					label={msg.util.error.mail.text}
					layout="always-label"
					style="link"
					href={msg.util.error.mail.href}
				/>
				{" "}
				{msg.util.error.text2}
				{" "}
				<ExternalLink
					label={msg.util.error.tel.text}
					layout="always-label"
					style="link"
					href={msg.util.error.tel.href}
				/>
			</p>
			<div class="signature">{msg.util.signature}</div>

			<ButtonBar width="auto">
				<Button
					label={msg.util.close}
					layout="always-label"
					style="primary"
					permission={permissions.useWebsite}
					action={() => actions.close()}
				/>
			</ButtonBar>
		</div>
	);
};
