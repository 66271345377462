import { JSX }	from "preact";

import { Endo }				from "ts-base/endo";

import { Button }			from "@v3/geko/Button";
import { ButtonBar }		from "@v3/geko/ButtonBar";
import { ExternalLink }		from "@v3/geko/ExternalLink";
import { FormWrapper }		from "@v3/geko/form/FormWrapper";
import { FormRow }			from "@v3/geko/form/FormRow";
// import { FormCell }			from "@v3/geko/form/FormCell";
import { AreaField }		from "@v3/geko/form/AreaField";
import { CheckboxField }	from "@v3/geko/form/CheckboxField";
import { RadioField }		from "@v3/geko/form/RadioField";
import { TextField }		from "@v3/geko/form/TextField";

import { permissions }		from "@geotoura/shared/authorization";

import { useLanguageCode }	from "@geotoura/common/util/useLanguageCode";

import {
	contactWays,
	MeetingGeneralModel,
	MeetingGeneralSchema,
	MeetingPhoneModel,
	MeetingPhoneSchema,
	FormState,
} from "@geotoura/floater/model";
import { useMessages }		from "@geotoura/floater/useMessages";
import * as actions			from "@geotoura/floater/actions";

import { Error }		from "@geotoura/floater/Error";
import { Success }		from "@geotoura/floater/Success";
import { Headline }		from "@geotoura/floater/Headline";

export type MeetingProps	= Readonly<{
	data: {
		general:	MeetingGeneralModel,
		phone:		MeetingPhoneModel,
	},
	state:	FormState,
	modifyGeneral:	(change:Endo<MeetingGeneralModel>) => void,
	modifyPhone:	(change:Endo<MeetingPhoneModel>) => void,
}>;

export const Meeting = ({ data, state, modifyGeneral, modifyPhone }:MeetingProps):JSX.Element => {
	const generalEditProps	= MeetingGeneralSchema.editorProps(data.general, modifyGeneral);
	const phoneEditProps	= MeetingPhoneSchema.editorProps(data.phone, modifyPhone);

	const languageCode	= useLanguageCode();
	const msg			= useMessages();

	return (
		<div class="floater-dialog inquiry">
			{ state === "error"	&&
				<Error/>
			}
			{ state === "ok"	&&
				<Success conversionType="conversion-meeting"/>
			}
			{ state === "form"	&&
				<FormWrapper>
					<Headline text1={msg.app.meeting1} text2={msg.app.meeting2}/>
					<div class="floater-dialog-intro">
						{msg.meeting.intro}
					</div>
					<FormRow>
						<RadioField
							label={msg.meeting.contactWay.label}
							missing={msg.form.fieldRequired}
							invalid={msg.form.fieldInvalid}
							choices={contactWays(msg)}
							{...generalEditProps.contactWay}
						/>
					</FormRow>
					<FormRow>
						<TextField
							type="email"
							missing={msg.form.fieldRequired}
							invalid={msg.form.fieldInvalid}
							label={msg.meeting.email.label}
							placeholder={msg.meeting.email.placeholder}
							{...generalEditProps.email}
						/>
					</FormRow>
					{ data.general.contactWay.value === "telephone" &&
						<FormRow>
							<TextField
								type="tel"
								missing={msg.form.fieldRequired}
								invalid={msg.form.fieldInvalid}
								label={msg.meeting.phone.label}
								placeholder={msg.meeting.phone.placeholder}
								{...phoneEditProps.phone}
							/>
						</FormRow>
					}
					<FormRow>
						<AreaField
							label={msg.meeting.notes.label}
							missing={msg.form.fieldRequired}
							invalid={msg.form.fieldInvalid}
							placeholder={msg.meeting.notes.placeholder}
							{...generalEditProps.notes}
						/>
					</FormRow>
					<FormRow>
						<CheckboxField
							label={
								<span>{msg.form.privacyAgreement.pre}
								<ExternalLink
									label={msg.form.privacyAgreement.link.text}
									layout="always-label"
									style="link"
									href={msg.form.privacyAgreement.link.href}
								/>
								{msg.form.privacyAgreement.post}
								</span>
							}
							missing={msg.form.fieldRequired}
							invalid={msg.form.fieldInvalid}
							{...generalEditProps.privacy}
						/>
					</FormRow>
					<ButtonBar width="auto">
						<Button
							label={msg.meeting.submitLabel}
							layout="always-label"
							style="primary"
							permission={permissions.useWebsite}
							action={() => actions.sendMeeting(languageCode)}
						/>
					</ButtonBar>
				</FormWrapper>
			}
		</div>
	);
};
