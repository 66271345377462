/* eslint-disable @stylistic/comma-dangle */

import { sharedLocale }	from "@geotoura/shared/sharedLocale_de";
import { siteInfo }		from "@geotoura/shared/siteInfo_de";

import { Messages } from "@geotoura/floater/locale";

export const messages:Messages = {
	app: {
		title:		"Schnellstart",
		inquiry1:	"Angebot",
		inquiry2:	"anfragen",
		planner1:	"Individuell",
		planner2:	"planen",
		meeting1:	"Persönlich",
		meeting2:	"beraten"
	},
	form: {
		fieldRequired:		"Dieses Feld ist ein Pflichtfeld.",
		fieldInvalid:		"Der Wert dieses Feldes ist ungültig.",
		privacyAgreement:	sharedLocale.privacyAgreement
	},
	inquiry: {
		introGeneral:	"Bitte senden Sie mir ein kostenloses und unverbindliches Reiseangebot.",
		introRoute:		"Bitte senden Sie mir ein kostenloses und unverbindliches Angebot für die Route",
		email: {
			label:			"E-Mail",
			placeholder:	"Ihre E-Mail-Adresse",
		},
		date: {
			label:			"Reisetermin",
			placeholder:	"Wann wollen Sie reisen?",
		},
		notes: {
			label:			"Meine Wünsche",
			placeholder:	"Hier ist Platz für Ihre Wünsche: Orte, die Sie besuchen möchten, besondere Anforderungen usw.",
		},
		submitLabel:	"Angebot zusenden"
	},
	meeting: {
		intro:	"Vereinbaren Sie eine persönliche Reiseberatung (Montag bis Freitag 9:30 – 18:00 Uhr)",
		email: {
			label:			"E-Mail",
			placeholder:	"Ihre E-Mail-Adresse",
		},
		phone: {
			label:			"Telefon",
			placeholder:	"Ihre Telefonnummer",
		},
		notes: {
			label:			"Termin und Wünsche",
			placeholder:	"Bitte nennen Sie uns Ihren Wunschtermin und gerne auch schon das Thema (Reiseziel, Reiseart …), für das Sie sich interessieren",
		},
		contactWay: {
			label:	"Wie möchten Sie am liebsten mit uns sprechen?",
			email:	"E-Mail",
			phone:	"Telefon",
			video:	"Video-Konferenz"
		},
		submitLabel:	"Beratung vereinbaren"
	},
	planner: {
		text1:			"Nutzen Sie unseren Urlaubsplaner! Entwerfen Sie Ihre Wunschreise nach Ihren ganz persönlichen Vorstellungen.",
		text2:			"Auf einer interaktiven Karte stellen Sie Reiseziele, Übernachtungen und Extras spielend leicht zusammen.",
		text3:			"Individueller kann Urlaub nicht sein!",
		textmobile1:	"Entwerfen Sie Ihre Wunschreise nach Ihren ganz persönlichen Vorstellungen.",
		textmobile2:	"Auf einer interaktiven Karte stellen Sie Reiseziele, Übernachtungen und Extras spielend leicht zusammen.",
		linkLabel:		"Jetzt Urlaubsplaner starten",
		wordpressBase:	siteInfo.wordpressBase,
		imgAlt:			"Screenshot eines Routenplaners. Er zeigt einen Kartenausschnitts auf dem Reiseziele mit gelben und grünen Markern eingezeichnet sind. Einige davon sind mit einer roten Linie zu einer Route verbunden. Daneben befindet sich eine Ansicht mit einem Foto (Meer und Palmen), Text zum ausgewählten Ort (Costa de Sol West), Hotels und eine Toolbar mit Buttons.",
	},
	util: {
		success:{
			title:	"Ihre Nachricht wurde verschickt",
			text:	"Vielen Dank für Ihre Anfrage. Wir werden diese baldmöglichst beantworten."
		},
		error: {
			title:	"Ein Fehler ist aufgetreten",
			text1:	"Ihre Nachricht konnte leider nicht verschickt werden. Bitte probieren Sie es später noch einmal, kontaktieren uns per E-Mail unter",
			mail:	sharedLocale.email.info,
			text2:	"oder rufen Sie uns einfach an unter",
			tel:	sharedLocale.phone,
		},
		signature:	"Ihr Geotoura-Team",
		close:		"Schließen",
	}
};
