import { Enum }	from "@v3/data/enum";

import {
	AccessControl,
	// Unauthenticated
}	from "@v3/data/accessControl";

export const { permissions, availablePermissions, accessAllowed }	=
	AccessControl.of<Role>()({
		useBooking:	[ "customer" ],
		useWebsite:	[ "customer" ],
	});

export type Role	= Enum.Value<typeof Role>;
export const Role	= Enum.of("customer");
