import { JSX }	from "preact";

import { Dom }	from "ts-base/web/dom";

import { Permission }			from "@v3/data/permission";
import { ProvidePermissions }	from "@v3/geko/permission/usePermissions";

import * as authorization		from "@geotoura/shared/authorization";
import * as i18n				from "@geotoura/shared/i18n";

import { ProvideLanguageCode }	from "@geotoura/common/util/useLanguageCode";

import { ProvideMessages }	from "@geotoura/floater/useMessages";
import { Messages }			from "@geotoura/floater/locale";
import { Model }			from "@geotoura/floater/model";
import * as actions			from "@geotoura/floater/actions";

import { Inquiry }			from "@geotoura/floater/Inquiry";
import { Planner }			from "@geotoura/floater/Planner";
import { Meeting }			from "@geotoura/floater/Meeting";
import { FloaterButton }	from "@geotoura/floater/FloaterButton";

export type AppProps = Readonly<{
	languageCode:	i18n.LanguageCode,
	msg:			Messages,
	model:			Model,
}>;

export const App = ({ languageCode, msg, model }:AppProps):JSX.Element =>
	<ProvidePermissions value={permissionSet}>
		<ProvideLanguageCode value={languageCode}>
			<ProvideMessages value={msg}>
				<div class={Dom.classes(
					"floater-inner",
					model.open !== null && "expanded",
					model.open !== null && model.open.display
				)}>
					<div class="floater-backdrop">
						<div class="floater-dialog-wrapper">
							{ model.open !== null && model.open.content === "inquiry" &&
								<Inquiry
									data={model.inquiry.data}
									state={model.inquiry.state}
									routeName={model.routeName}
									pageInfo={model.pageInfo}
									modifyInquiryForm={actions.modifyInquiryForm}
								/>
							}
							{ model.open !== null && model.open.content === "planner" &&
								<Planner pageInfo={model.pageInfo}/>
							}
							{ model.open !== null && model.open.content === "meeting" &&
								<Meeting
									data={model.meeting.data}
									state={model.meeting.state}
									modifyGeneral={actions.modifyMeetingGeneralForm}
									modifyPhone={actions.modifyMeetingPhoneForm}
								/>
							}
						</div>
					</div>
					<div class="floater-buttonbar">
						<div class="floater-start">
							<div class="floater-start-text">{msg.app.title}</div>
						</div>
						<FloaterButton
							icon="envelope"
							text1={msg.app.inquiry1}
							text2={msg.app.inquiry2}
							open={model.open !== null && model.open.content === "inquiry"}
							action={() => actions.toggle("inquiry", "attached")}
						/>
						<FloaterButton
							icon="magnifying-glass-location"
							text1={msg.app.planner1}
							text2={msg.app.planner2}
							open={model.open !== null && model.open.content === "planner"}
							action={() => actions.toggle("planner", "attached")}
						/>
						<FloaterButton
							icon="comments-question-check"
							text1={msg.app.meeting1}
							text2={msg.app.meeting2}
							open={model.open !== null && model.open.content === "meeting"}
							action={() => actions.toggle("meeting", "attached")}
						/>
					</div>
				</div>
			</ProvideMessages>
		</ProvideLanguageCode>
	</ProvidePermissions>;

//-----------------------------------------------------------------------------

// right now, we assume everyone is a (logged in...) customer
// and nobody is ever an Unauthenticated
const permissionSet:ReadonlySet<Permission>	=
	authorization.availablePermissions({
		roles:	[ "customer" ],
	});
