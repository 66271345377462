/* eslint-disable @stylistic/comma-dangle */

import { sharedLocale }	from "@geotoura/shared/sharedLocale_it";
import { siteInfo }		from "@geotoura/shared/siteInfo_it";

import { Messages } from "@geotoura/floater/locale";

export const messages:Messages = {
	app: {
		title:		"Iniziare subito",
		inquiry1:	"Richiedere",
		inquiry2:	"un’offerta",
		planner1:	"Pianificare",
		planner2:	"",
		meeting1:	"Consigli",
		meeting2:	"personalizzati"
	},
	form: {
		fieldRequired:		"Questo campo è obbligatorio.",
		fieldInvalid:		"Il valore di questo campo non è valido.",
		privacyAgreement:	sharedLocale.privacyAgreement
	},
	inquiry: {
		introGeneral:	"Per favore inviatemi un’offerta gratuita e non vincolante.",
		introRoute:		"Per favore inviatemi un’offerta gratuita e non vincolante per questo itinerario",
		email: {
			label:			"E-mail",
			placeholder:	"Il tuo indirizzo e-mail",
		},
		date: {
			label:			"Date di viaggio",
			placeholder:	"Quando desideri viaggiare?",
		},
		notes: {
			label:			"I miei desideri",
			placeholder:	"Qui c’è posto per i tuoi desideri: luoghi che desideri visitare, esigenze speciali, ecc.",
		},
		submitLabel:	"‌Inviare l’offerta"
	},
	meeting: {
		intro:	"Richiedi una consulenza di viaggio (Da lunedì a venerdì 9:30 – 18:00)",
		email: {
			label:			"E-mail",
			placeholder:	"Il tuo indirizzo e-mail",
		},
		phone: {
			label:			"Telefono",
			placeholder:	"Il tuo numero di telefono",
		},
		notes: {
			label:			"Appuntamento e desideri",
			placeholder:	"Ci puoi comunicare la data che preferisci e, se vuoi, l’argomento (destinazione, tipo di viaggio …) che ti interessa.",
		},
		contactWay: {
			label:	"Come preferisci comunicare con noi?",
			email:	"E-mail",
			phone:	"Telefono",
			video:	"Video conferenza"
		},
		submitLabel:	"Richiedi una consulenza"
	},
	planner: {
		text1:			"Utilizza il nostro pianificatore per le vacanze! Progetta il tuo viaggio da sogno a seconda delle tue idee personali.",
		text2:			"Puoi facilmente mettere insieme destinazioni, pernottamenti ed extra su una mappa interattiva.",
		text3:			"Le vacanze non possono essere più individuali di così!",
		textmobile1:	"Progetta il tuo viaggio da sogno secondo le tue idee personali.",
		textmobile2:	"Su una mappa interattiva si possono facilmente mettere insieme destinazioni, pernottamenti ed extra.",
		linkLabel:		"Inizia a pianificare le vacanze adesso",
		wordpressBase:	siteInfo.wordpressBase,
		imgAlt:			"Screenshot di un pianificatore di percorso. Mostra una sezione della mappa in cui le destinazioni di viaggio sono contrassegnate con indicatori gialli e verdi. Alcuni di essi sono collegati da un percorso contrassegnato da una linea rossa. Accanto c'è una vista con una foto (mare e palme), un testo sulla località selezionata (Costa del Sol Ovest), gli hotel e una barra degli strumenti con i pulsanti.",
	},
	util: {
		success:{
			title:	"Il tuo messaggio è stato inviato",
			text:	"Grazie per la tua richiesta. Risponderemo al più presto."
		},
		error: {
			title:	"Si è verificato un errore",
			text1:	"Purtroppo il tuo messaggio non è stato inviato. Prego riprovare più tardi, contattarci al seguente indirizzo e-mail",
			mail:	sharedLocale.email.info,
			text2:	"o chiamarci al",
			tel:	sharedLocale.phone,
		},
		signature:	"Il tuo Team Geotoura",
		close:		"Chiudi",
	}
};
