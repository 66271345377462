import { JSX }	from "preact";

import { Dom }	from "ts-base/web/dom";

import { Button }		from "@v3/geko/Button";
import { ButtonBar }	from "@v3/geko/ButtonBar";

import { permissions }	from "@geotoura/shared/authorization";

import { useMessages }	from "@geotoura/floater/useMessages";
import * as actions		from "@geotoura/floater/actions";
import { Headline }		from "@geotoura/floater/Headline";

export type SuccessProps	= Readonly<{
	/* DO NOT CHANGE: these strings are used for conversion tracking by Google Tag Manager */
	conversionType:	"conversion-meeting"|"conversion-inquiry",
}>;

export const Success = ({ conversionType }:SuccessProps):JSX.Element => {
	const msg = useMessages();

	return (
		<div class={Dom.classes(
			"floater-success",
			conversionType
		)}>
			<Headline text1={msg.util.success.title}/>
			<p>{msg.util.success.text}</p>
			<div class="signature">{msg.util.signature}</div>
			<ButtonBar width="auto">
				<Button
					label={msg.util.close}
					layout="always-label"
					style="primary"
					permission={permissions.useWebsite}
					action={() => actions.close()}
				/>
			</ButtonBar>
		</div>
	);
};
