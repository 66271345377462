import { JSX }	from "preact";

import { Endo }				from "ts-base/endo";

import { Button }			from "@v3/geko/Button";
import { ButtonBar }		from "@v3/geko/ButtonBar";
import { ExternalLink }		from "@v3/geko/ExternalLink";
import { FormWrapper }		from "@v3/geko/form/FormWrapper";
import { FormRow }			from "@v3/geko/form/FormRow";
import { AreaField }		from "@v3/geko/form/AreaField";
import { CheckboxField }	from "@v3/geko/form/CheckboxField";
import { TextField }		from "@v3/geko/form/TextField";

import { permissions }		from "@geotoura/shared/authorization";

import { useLanguageCode }	from "@geotoura/common/util/useLanguageCode";
import { PageInfo }			from "@geotoura/common/pageInfo";

import { useMessages }	from "@geotoura/floater/useMessages";
import * as actions		from "@geotoura/floater/actions";
import { InquirySchema, InquiryModel, FormState }	from "@geotoura/floater/model";

import { Error }		from "@geotoura/floater/Error";
import { Success }		from "@geotoura/floater/Success";
import { Headline }		from "@geotoura/floater/Headline";

export type InquiryProps	= Readonly<{
	data:				InquiryModel,
	state:				FormState,
	routeName:			string|null,
	pageInfo:			PageInfo,
	modifyInquiryForm:	(change:Endo<InquiryModel>) => void,
}>;

export const Inquiry = ({ data, state, routeName, pageInfo, modifyInquiryForm }:InquiryProps):JSX.Element => {
	const editProps	= InquirySchema.editorProps(data, modifyInquiryForm);

	const languageCode	= useLanguageCode();
	const msg			= useMessages();

	return (
		<div class="floater-dialog inquiry">
			{ state === "error"	&& <Error/> }
			{ state === "ok"	&& <Success conversionType="conversion-inquiry"/> }
			{ state === "form"	&&
				<FormWrapper>
					<Headline text1={msg.app.inquiry1} text2={msg.app.inquiry2}/>
					<div class="floater-dialog-intro">
						<p>{
							(pageInfo.type === "route" || pageInfo.type === "region") && routeName !== null
							? `${msg.inquiry.introRoute} "${routeName}". `
							: msg.inquiry.introGeneral
							}
						</p>
					</div>
					<FormRow>
						<TextField
							type="email"
							missing={msg.form.fieldRequired}
							invalid={msg.form.fieldInvalid}
							label={msg.inquiry.email.label}
							placeholder={msg.inquiry.email.placeholder}
							{...editProps.email}
						/>
					</FormRow>
					<FormRow>
						<TextField
							type="text"
							missing={msg.form.fieldRequired}
							invalid={msg.form.fieldInvalid}
							label={msg.inquiry.date.label}
							placeholder={msg.inquiry.date.placeholder}
							{...editProps.date}
						/>
					</FormRow>
					<FormRow>
						<AreaField
							label={msg.inquiry.notes.label}
							missing={msg.form.fieldRequired}
							invalid={msg.form.fieldInvalid}
							placeholder={msg.inquiry.notes.placeholder}
							{...editProps.notes}
						/>
					</FormRow>
					<FormRow>
						<CheckboxField
							label={
								<span>{msg.form.privacyAgreement.pre}
								<ExternalLink
									label={msg.form.privacyAgreement.link.text}
									layout="always-label"
									style="link"
									href={msg.form.privacyAgreement.link.href}
								/>
								{msg.form.privacyAgreement.post}
								</span>
							}
							missing={msg.form.fieldRequired}
							invalid={msg.form.fieldInvalid}
							{...editProps.privacy}
						/>
					</FormRow>
					<ButtonBar width="auto">
						<Button
							label={msg.inquiry.submitLabel}
							layout="always-label"
							style="primary"
							permission={permissions.useWebsite}
							action={() => actions.sendInquiry(languageCode)}
						/>
					</ButtonBar>
				</FormWrapper>
			}
		</div>
	);
};
