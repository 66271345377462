import { Nullable }		from "ts-base/nullable";

import { Refresh }		from "@v3/preact/refresh";

import * as i18n		from "@geotoura/shared/i18n";

import * as pageInfo	from "@geotoura/common/pageInfo";

import { Messages }		from "@geotoura/floater/locale";
import { Model }		from "@geotoura/floater/model";
import { App }			from "@geotoura/floater/App";
import * as actions		from "@geotoura/floater/actions";

const init	= ():void	=> {
	const languageCode:i18n.LanguageCode	=
		i18n.LanguageCode.valueOrDefault(document.documentElement.getAttribute("lang"));

	const page = pageInfo.get();
	const msg	= Messages.of[languageCode];

	const container	= Nullable.unsafeGet(document.querySelector<Element>("#floater"));

	const refresh:Refresh<Model> =
		Refresh.install({
			container:	container,
			initial:	Model.initial(page),
			component:	model => <App languageCode={languageCode} msg={msg} model={model}/>,
		});
	actions.setRefresh(refresh);

	if (page.type === "route" && page.routeId !== null) {
		void actions.getData(languageCode, page.routeId);
	}

	// open the meeting from php-generated buttons
	document.querySelectorAll<HTMLButtonElement>(".js-open-meeting").forEach((button) =>
		button.addEventListener("click", () =>
			actions.toggle("meeting", "centered")
		)
	);

	// open the inquiry from php-generated buttons
	document.querySelectorAll<HTMLButtonElement>(".js-open-inquiry").forEach((button) =>
		button.addEventListener("click", () =>
			actions.toggle("inquiry", "centered")
		)
	);
};

window.addEventListener("DOMContentLoaded", init);
